<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Titulo" v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
          icon="el-icon-search">Buscar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
          icon="el-icon-delete">Limpiar</el-button>
      </el-col>
    </el-row>
    <!---- Paginacion -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
    <el-row type="flex" justify="start">
        <el-button @click.stop.prevent="handleCreateAchievement" class="margin-left-10" size="mini" type="success"
          icon="el-icon-plus">Agregar Logro</el-button>
    </el-row>
    <!---- Tabla de Logros ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="ID" fixed width="140" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row._id !== undefined ? scope.row._id : 'No disponible' }}</div>
            <span>{{ scope.row._id !== undefined ? scope.row._id.toString().substr(-8) : 'N/D' }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Ícono Desbloqueado" width="150" align="center">
        <template slot-scope="scope">
              <div class="guide-image">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="`${pathServer + scope.row.icon}`"
                  fit="scale-down"
                  :description="scope.row.description"
                  :preview-src-list="[pathServer + scope.row.icon]">
                </el-image>
              </div>
        </template>
      </el-table-column>
      <el-table-column label="Ícono Bloqueado" width="150" align="center">
        <template slot-scope="scope">
              <div class="guide-image">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="`${pathServer + scope.row.iconDisabled}`"
                  fit="scale-down"
                  :preview-src-list="[pathServer + scope.row.iconDisabled]">
                </el-image>
              </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Vigencia Logro" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.expiration !==undefined ? scope.row.expiration : 'N/D' | formatDate}}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="Título" align="center" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.title !== undefined ? scope.row.title : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre de Clave" align="center" width="160">
        <template slot-scope="scope">
          <span>{{ scope.row.keyName !== undefined ? scope.row.keyName : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Patrocinador" align="center" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.sponsor !== undefined ? scope.row.sponsor : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Tipo" align="center" width="200">
        <template slot-scope="scope">
          <el-button v-if="scope.row.type == 'qr'" size="mini" type="success" @click="handleShowQR(scope.row)">{{ traslatedType(scope.row.type) }}</el-button>
          <span v-else >{{ scope.row.type !== undefined ? traslatedType(scope.row.type) : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Opciones" width="210" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEditAchievement(scope.row._id)">Editar</el-button>
          <el-button v-if="scope.row.type !== 'programmed'" size="mini" type="danger" plain @click="handleDeleteAchievement(scope.$index, scope.row)">Eliminar</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      align="middle">
      <vue-qrcode id="qrcode" :value="handleObjectQR()" tag="img" :options="handleOptionsQR()"></vue-qrcode>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="success" @click="handleDownloadQR()">Descargar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { search, getPage, deleteAchievement } from '@/api/achievements.js'
import VueQrcode from '@chenfengyuan/vue-qrcode'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi

export default {
  name: 'Achievements',
  components: {
    VueQrcode
  },
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: ''
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      list: [
      ],
      imgList: [],
      dialogVisible: false,
      keyName: '',
      title: ''
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  computed: {
    getArrayImages () {
      this.list.forEach((item) => {
        this.imgList.push(this.pathServer + item.icon)
      })
      return this.imgList
    }
  },
  methods: {
    // ---- Data guides ------
    handleCreateAchievement () {
      this.$router.push('achievements/create')
    },
    handleDownloadDocument (file) {
    },
    handleEditAchievement (id) {
      console.log('scope')
      this.$router.push(`achievements/edit/${id}`)
    },
    handleDeleteAchievement (index, row) {
      this.$confirm('Se borrará permanentemente. ¿Desea continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await deleteAchievement(row._id)
            .then(response => {
              this.$message({
                showClose: true,
                message: '¡Se eliminó correctamente!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Operacion cancelada'
          })
        })
        .finally(() => (this.loading = false))
    },
    // --- data search ----
    async handleSearchFilter () {
      console.log('get achievements')
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          console.log(response)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    traslatedType (contain) {
      switch (contain) {
        case 'programmed':
          return 'Programado'
        case 'qr':
          return 'Código QR'
        case 'customized':
          return 'Personalizado'
        default:
          return contain
      }
    },
    handleClean () {
      this.searchForm.query = ''
      this.handleSearchFilter()
    },
    handleShowQR (object) {
      this.title = 'QR - ' + object.title
      this.keyName = object.keyName
      this.dialogVisible = true
    },
    handleObjectQR () {
      return JSON.stringify({
        keyName: this.keyName,
        descriptor: 'probosque'
      })
    },
    handleOptionsQR () {
      return {
        color: {
          dark: '#2A520F',
          light: '#FFFFFF'
        },
        width: 300
      }
    },
    handleDownloadQR () {
      var elementQR = document.getElementById('qrcode')
      var href = elementQR.src
      const a = document.createElement('a')
      a.download = this.title
      a.href = href
      document.body.append()
      a.click()
      a.remove()
    }
  }
}
</script>

<style lang="css">

</style>
